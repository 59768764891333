<template>
  <div v-if="loadingTable == true && data.length === 0">
    <b-skeleton type="button" width="80px" class="skeleton-button"></b-skeleton>
    <div class="skeleton-wrapper">
      <div class="skeleton-filter">
        <b-skeleton animation="wave" width="100px" class="skeleton-title"></b-skeleton>
        <b-skeleton type="input"></b-skeleton>
      </div>
      <div class="skeleton-date">
        <b-skeleton animation="wave" width="100px" class="skeleton-title"></b-skeleton>
        <b-skeleton type="input"></b-skeleton>
      </div>
    </div>
    <div class="skeleton-wrapper">
      <div class="skeleton-organization">
        <b-skeleton animation="wave" width="100px" class="skeleton-title"></b-skeleton>
        <b-skeleton type="input"></b-skeleton>
      </div>
      <div class="skeleton-partner">
        <b-skeleton animation="wave" width="100px" class="skeleton-title"></b-skeleton>
        <b-skeleton type="input"></b-skeleton>
      </div>
    </div>
    <b-skeleton-table
      :rows="10"
      :columns="9"
      :table-props="{ bordered: true }"
    ></b-skeleton-table>
  </div>
  <div v-else>
    <div class="loading" v-if="isLoading == true">
        <div class="sk-three-bounce">
            <div class="sk-child sk-bounce1"></div>
            <div class="sk-child sk-bounce2"></div>
            <div class="sk-child sk-bounce3"></div>
        </div>
    </div>
    <b-row class="mb-3" v-if="$can('export')">
      <b-col sm>
          <div>
            <button class="btn btn-success" type="button" @click="exportTable">Export</button>
          </div>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col sm>
        <div>
          <b-form-group
            class="mb-3"
            label="Search :"
            label-for="search"
            description="Searchable : Name, Email, Phone"
          >
            <b-input-group
            >
              <b-form-input
                id="search"
                type="search"
                v-model="filterText"
                placeholder="Name, Email, Phone ..."
                @keyup.enter="doFilter"
              >
              </b-form-input>
              <b-input-group-append
              >
                <b-button
                  variant="secondary" @click="resetFilter"
                  type="button"
                >
                  Reset
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-col>
      <b-col sm>
        <div>
          <b-form-group
            class="mb-3"
            label="Date :"
            label-for="daterange"
          >
            <b-input-group
            >
              <b-input-group-prepend is-text>
                <i class="fa fa-calendar"></i>
              </b-input-group-prepend>
              <range-picker id="daterange" :start="startDate" :end="endDate" @picker="doDateFilter"></range-picker>
              <b-input-group-append>
                <b-button type="button" variant="secondary" @click="resetDateFilter">Reset</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <!-- <b-col sm>
        <div>
          <b-form-group
            class="mb-3"
            label="Search By Organization :"
            label-for="so"
          >
            <b-form-select id="so" v-model="organization" :options="organizationOptions" @change="doFilterOrganization"></b-form-select>
          </b-form-group>
        </div>
      </b-col> -->
      <b-col sm="6">
        <div>
          <b-form-group
            class="mb-3"
            label="Search By Partner :"
            label-for="sp"
          >
            <b-form-select id="sp" v-model="partner" :options="partnerOptions" @change="doFilterPartner"></b-form-select>
          </b-form-group>
        </div>
      </b-col>
    </b-row>
    <vuetable ref="vuetable"
      :api-mode="false"
			:fields="fields"
      :per-page="limit"
			:data-manager="dataManager"
    >
      <template slot="name-slot" slot-scope="prop">
        <span>{{ prop.rowData.name }}</span>
      </template>
      <template slot="email-slot" slot-scope="prop">
        <span>{{ prop.rowData.email }}</span>
      </template>
      <template slot="referral_code-slot" slot-scope="prop">
        <span>{{ prop.rowData.referral_code == null ? '-' : prop.rowData.referral_code }}</span>
      </template>
      <template slot="organization-slot" slot-scope="prop">
        <span>{{ prop.rowData.organization}}</span>
      </template>
      <template slot="partner-slot" slot-scope="prop">
        <span>{{ prop.rowData.partner}}</span>
      </template>
      <template slot="actions-slot" slot-scope="prop">
        <div class="custom-actions">
          <button class="btn btn-success" @click="onAction('view-item', prop.rowData, prop.rowIndex)">Detail</button>&nbsp;
          <button class="btn btn-warning" v-if="$can('reset_pin')" @click="onAction('reset-pin-item', prop.rowData, prop.rowIndex)">Reset Pin</button>&nbsp;
          <button class="btn btn-danger" v-if="prop.rowData.is_blacklist === false && $can('user_block')" @click="onAction('block-item', prop.rowData, prop.rowIndex)">Block</button>&nbsp;
          <button class="btn btn-info" v-if="prop.rowData.is_blacklist === true && $can('user_block')" @click="onAction('unblock-item', prop.rowData, prop.rowIndex)">Unblock</button>
        </div>
      </template>
    </vuetable>
    <div class="vuetable-pagination ui basic segment grid">
      <div class="wrapper-pagination-custom">
        <div class="pagination-custom-info">
          <p>{{ this.paginationInfo }}</p>
        </div>
        <div class="pagination-custom-button">
          <button :disabled="currentPage === 1" @click="doMovePage('first')">
            <i v-if="currentPage === 1" class="fa fa-angle-double-left disabled"></i>
            <i v-else class="fa fa-angle-double-left"></i>
          </button>
          <button :disabled="currentPage === 1" @click="doMovePage('prev')">
            <i v-if="currentPage === 1" class="fa fa-angle-left disabled"></i>
            <i v-else class="fa fa-angle-left"></i>
          </button>
          <div>{{ this.currentPage }}</div>
          <button :disabled="data.length < 10" @click="doMovePage('next')">
            <i v-if="data.length < 10" class="fa fa-angle-right disabled"></i>
            <i v-else class="fa fa-angle-right"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'
  import axios from 'axios'
  import Vuetable from 'vuetable-2/src/components/Vuetable'
  import _ from 'lodash'

  let startDate = '';
  let endDate = '';
  Vue.use(Vuetable);

  export default {
    components: {
      Vuetable
    },
    prop: {
      rowData: {
        type: Object,
        required: true
      },
      rowIndex: {
        type: Number
      }
    },
    data() {
      return {
        page: 1,
        currentPage: 1,
        limit: 10,
        data: [],
        startDate: '',
        endDate: '',
        loadingTable: true,
        isLoading: true,
        filterText: '',
        dateDetail: {
          start_from: '',
          end_from: ''
        },
        errors: {
          code: '',
          message: '',
          status: ''
        },
        organization: '',
        organizationOptions: [
        { value: '', text: 'Select Organization'}
        ],
        partner: '',
        partnerOptions: [
          { value: '', text: 'Select Partner'}
        ],
        apiUrl: process.env.VUE_APP_SECRET + process.env.VUE_APP_CONFIG + `end-user`,
        HttpOptions: {
          headers: {
            'Accept' : 'application/json',
            'Authorization' : 'Bearer ' + localStorage.getItem('access_token')
          }
        },
        sort: 'created_at|desc',
        sortOrder: [
          {
            field: 'created_at',
            sortField: 'created_at',
            direction: 'desc'
          }
        ],
        moreParams: {},
        fields: [
          {
            name: 'name-slot',
            sortField: 'name',
            title: 'Name',
            width: '20%',
            length: '10'
          },
          {
            name: 'email-slot',
            sortField: 'email',
            title: 'Email'
          },
          {
            name: 'referral_code-slot',
            title: 'Referral Code'
          },
          {
            name: 'organization-slot',
            title: 'Organization'
          },
          {
            name: 'partner-slot',
            title: 'Partner'
          },
          {
            name: 'gender',
            sortField: 'gender',
            titleClass: 'center aligned',
            dataClass: 'center aligned',
            formatter: this.genderLabel
          },
          {
            name: 'birthday',
            sortField: 'birthday',
            title: 'Date of Birth',
            titleClass: 'center aligned',
            dataClass: 'center aligned',
            formatter: this.formatDate('DD-MM-YYYY')
          },
          {
            name: 'created_at',
            sortField: 'created_at',
            titleClass: 'center aligned',
            dataClass: 'center aligned',
            title: 'Register Date'
          },
          {
            name: 'actions-slot',
            title: 'Actions',
            titleClass: 'center aligned',
            dataClass: 'center aligned'
          }
        ]
      }
    },
    watch: {
      // eslint-disable-next-line no-unused-vars
      data(newVal, oldVal) {
        this.$refs.vuetable.refresh();
      }
    },
    created() {
      this.defaultValue()
      axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`
      axios.get(process.env.VUE_APP_SECRET + 'admin/v2/command/organization-command').then((response) => {
        this.organizationOptions = [
          ...this.organizationOptions,
          ...response.data.data
        ]
      }),
      axios.get(process.env.VUE_APP_SECRET + 'admin/v1/partner-list').then((response) => {
        const newPartner = response.data.map((item) => { return { value: item.id, text: item.name } })
        this.isLoading = false
        this.partnerOptions = [
          ...this.partnerOptions,
          ...newPartner
        ]
      })
      this.onFetch()
    },
    methods: {
      defaultValue(){
        this.partner = 1
        this.startDate = this.moment().subtract(2,'days').format('YYYY-MM-DD')
        this.endDate = this.moment().format('YYYY-MM-DD')
        this.moreParams.start_from = this.startDate
        this.moreParams.end_to = this.endDate
        this.moreParams.partner = this.partner
      },
      onFetch() {
        const newParams = Object.entries(this.moreParams).filter(([value]) => value !== '').map(([key, value]) => `${key}=${value}`).join('&');

        this.$http.get(`end-user?sort=${encodeURIComponent(this.sort)}&page=${this.page}&per_page=10&${newParams}`)
          .then((response) => {
            const startIndex = (response.data.current_page - 1) * this.limit;
            const endIndex = startIndex + this.limit;

            this.data = response.data.data;
            this.currentPage = response.data.current_page;
            this.paginationInfo = response.data.data.length > 0 ? `Displaying ${startIndex + 1} to ${endIndex}` : 'No relevant data';

            if (this.loadingTable) this.loadingTable = false;
          })
          .catch((err) => {
            this.handleLoadError(err)
          })
      },
      doMovePage(value) {
        this.$events.$emit('movePage', value)
      },
      doFilter () {
        this.$events.$emit('filter-set', this.filterText)
      },
      resetFilter () {
        this.filterText = ''  // clear the text in text input
        this.$events.$emit('filter-reset')
      },
      doFilterOrganization () {
        this.$events.$emit('organization-set', this.organization)
      },
      doFilterPartner () {
        this.$events.$emit('partner-set', this.partner)
      },
      onOrganizationFilterSet (organization) {
        if (organization) {
          const newParams = Object.fromEntries(Object.entries(this.moreParams).filter(([key]) => key !== 'organization'))
          this.page = 1
          this.moreParams = {
            ...newParams,
            'organization' : organization
          }
          this.onFetch()
        } else {
          this.moreParams = Object.fromEntries(Object.entries(this.moreParams).filter(([key]) => key !== 'organization'))
          this.onFetch()
        }
      },
      onPartnerFilterSet (partner) {
        if (partner) {
          const newParams = Object.fromEntries(Object.entries(this.moreParams).filter(([key]) => key !== 'partner'))
          this.page = 1
          this.moreParams = {
            ...newParams,
            'partner' : partner
          }
          this.onFetch()
        } else {
          this.moreParams = Object.fromEntries(Object.entries(this.moreParams).filter(([key]) => key !== 'partner'))
          this.onFetch()
        }
      },
      exportTable() {
        this.isLoading = true
        if (this.startDate != '' && this.endDate != '') {
          startDate = this.startDate;
          endDate = this.endDate;
        }else{
          endDate = this.$setDate.format('YYYY-MM-DD');
          startDate = this.$setDate.subtract(6, 'days').format('YYYY-MM-DD');
        }
        this.$http.get(`end-user/${startDate}/${endDate}?filter=${encodeURIComponent(this.filterText)}&partner=${this.partner}&organization=${this.organization}`)
        .then((result) => {
          this.isLoading = false
          const  exportPath = result.data.meta.data;
          window.location.href = exportPath;
        }).catch((error) => {
          if (error.response) {
            this.isLoading = false
            this.errors.code = error.response.status;
            this.errors.status = error.response.data.meta.code;
            this.errors.headers = error.response.headers;
          }
        })
      },
      handleLoadError(error) {
        this.errors.code = error.response.data.meta.code;
        this.errors.message = error.response.data.meta.message;
        this.errors.status = error.response.data.meta.code;
        if (this.errors.code == 401) {
          if (localStorage.getItem('access_token') != null) {
            localStorage.removeItem('access_token');
            this.$swal.fire(
              'Your session expired!',
              'Your session has expired. Please login again to access this page!',
              'error'
            ).then(() =>{
              this.$router.push("/login")
            })
          }
        }else if(this.errors.code == 403) {
          this.$router.push("/403")
        }else if(this.errors.code == 500) {
          this.$router.push("/500")
        }
      },
      resetDateFilter () {
        this.startDate = ''
        this.endDate = ''
        this.$events.$emit('date-filter-reset')
      },
      allcap (value) {
        return value.toUpperCase()
      },
      genderLabel (value) {
        if (value == null) {
          return '<span class="ui red label">Unknown</span>'
        }else if(value == 'Male'){
          return '<span class="ui teal label"><i class="large man icon"></i>Male</span>'
        }else{
          return '<span class="ui pink label"><i class="large woman icon"></i>Female</span>'
        }
      },
      formatDate (value, fmt = 'D MM YYYY') {
        return (value == null)
          ? '-'
          : this.moment(value, 'YYYY-MM-DD').format(fmt)
      },
      onChangePage (page) {
        this.$refs.vuetable.changePage(page)
      },
      onPaginationData (paginationData) {
        this.$refs.pagination.setPaginationData(paginationData)
        this.$refs.paginationInfo.setPaginationData(paginationData)
      },
      onAction (action, data) {
        if (action == 'view-item') {
          const routeData = this.$router.resolve({name: 'Detail End User', params: { id: btoa(data.id)}});
          window.open(routeData.href, '_blank');
        } else if (action == 'block-item') {
          this.$swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this User!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, block!',
            cancelButtonText: 'No, keep it'
          }).then((result) => {
              if (result.value) {
                this.$http.post(`blacklist-user/` + data.id)
                .then(() => {
                  this.$swal.fire(
                    'Success!',
                    'This User has been block.',
                    'success'
                  ).then(() => {
                    location.reload();
                  }).catch((error) => {
                    if (error.response) {
                      this.errors.code = error.response.status;
                      this.errors.message = error.response.data.meta.message;
                      this.errors.status = error.response.data.meta.code;
                    }
                  })
                })
              } else if (result.dismiss === this.$swal.DismissReason.cancel) {
                this.$swal.fire(
                  'Cancelled',
                  'This User has been successfully canceled for block!',
                  'error'
                )
              }
          })
        }else if (action == 'unblock-item') {
          this.$swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this User!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, unblock!',
            cancelButtonText: 'No, keep it'
          }).then((result) => {
              if (result.value) {
                this.$http.post(`unblacklist-user/` + data.id)
                .then(() => {
                  this.$swal.fire(
                    'Success!',
                    'This User has been unblock.',
                    'success'
                  ).then(() => {
                    location.reload();
                  }).catch((error) => {
                    if (error.response) {
                      this.errors.code = error.response.status;
                      this.errors.message = error.response.data.meta.message;
                      this.errors.status = error.response.data.meta.code;
                    }
                  })
                })
              } else if (result.dismiss === this.$swal.DismissReason.cancel) {
                this.$swal.fire(
                  'Cancelled',
                  'This User has been successfully canceled for unblock!',
                  'error'
                )
              }
          })
        }else{
          this.$swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this User!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, reset it!',
            cancelButtonText: 'No, keep it'
          }).then((result) => {
              if (result.value) {
                this.$http.post(`reset-pin/` + data.id)
                .then(() => {
                  this.$swal.fire(
                    'Success!',
                    'This User has been reset pin.',
                    'success'
                  ).then(() => {
                    location.reload();
                  }).catch((error) => {
                    if (error.response) {
                      this.errors.code = error.response.status;
                      this.errors.message = error.response.data.meta.message;
                      this.errors.status = error.response.data.meta.code;
                    }
                  })
                })
              } else if (result.dismiss === this.$swal.DismissReason.cancel) {
                this.$swal.fire(
                  'Cancelled',
                  'This User has been successfully canceled for pin resetting!',
                  'error'
                )
              }
          })
        }
      },
      onFilterSet (filterText) {
        const newParams = Object.fromEntries(Object.entries(this.moreParams).filter(([key]) => key !== 'filter'))
        this.page = 1
        this.moreParams = {
          // ...newParams,
          'filter': filterText
        }
        this.onFetch()
      },
      doDateFilter(value){
        this.startDate = value.startDate;
        this.endDate = value.endDate;
        this.$events.fire('date-set', [this.startDate, this.endDate])
      },
      onDateSet()  {
        const newParams = Object.fromEntries(Object.entries(this.moreParams).filter(([key]) => (key !== 'start_from') && (key != 'end_to')))
        this.start_from = this.startDate
        this.end_to = this.endDate
        this.page = 1;
        this.moreParams = {
          ...newParams,
          'start_from': this.start_from,
          'end_to': this.end_to
        }
        this.onFetch()
      },
      onFilterReset () {
        this.moreParams = Object.fromEntries(Object.entries(this.moreParams).filter(([key]) => key !== 'filter'))
        this.page = 1
        this.onFetch()
      },
      onDateFilterReset () {
        this.moreParams = Object.fromEntries(Object.entries(this.moreParams).filter(([key]) => (key !== 'start_from') && (key != 'end_to')))
        this.page = 1
        this.onFetch()
      },
      onMovePage (eventData) {
        if (eventData === 'first') {
          this.page = 1;
          this.onFetch();
        } else if (eventData === 'prev') {
          this.page--;
          this.onFetch();
        } else {
          this.page++;
          this.onFetch();
        }
      },
      dataManager(sortOrder, pagination) {
        let local = this.data;

        if (sortOrder.length > 0) {
          const newSort = `${sortOrder[0].sortField}|${sortOrder[0].direction}`;
          if (this.sort !== newSort) {
            this.sort = newSort;
            this.onFetch();
          }
        }

        pagination = this.$refs.vuetable.makePagination(
          local.length,
          this.limit
        );

        let from = pagination.from - 1;
        let to = from + this.limit;

        return {
          pagination: pagination,
          data: local.length > 0 ? _.slice(local, from, to) : []
        };
      }
    },
    mounted() {
      this.$events.$on('filter-set', eventData => this.onFilterSet(eventData))
      this.$events.$on('date-set', eventData => this.onDateSet(eventData))
      this.$events.$on('organization-set', eventData => this.onOrganizationFilterSet(eventData))
      this.$events.$on('partner-set', eventData => this.onPartnerFilterSet(eventData))
      this.$events.$on('filter-reset', () => this.onFilterReset())
      this.$events.$on('date-filter-reset', () => this.onDateFilterReset()),
      this.$events.$on('movePage', eventData => this.onMovePage(eventData))
    },
  }
</script>
<style>
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}

/* Pagination Custom */
.wrapper-pagination-custom {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f9fafb;
}

.pagination-custom-button {
  display: flex;
}

.pagination-custom-button button {
  width: 28px;
  height: 47px;
  padding: 13px 16px;
  font-size: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  border: solid rgba(0,0,0,0.3);
}

.pagination-custom-button button:first-child {
  padding: 13px 16px 13px 30px;
  border-radius: 4px 0 0 4px;
  border-width: 1px 0 1px 1px
}

.pagination-custom-button button:nth-child(2),
.pagination-custom-button button:last-child {
  font-size: 1.3em;
  border-width: 1px;
}

.pagination-custom-button button:last-child {
  border-radius: 0 4px 4px 0;
}

.pagination-custom-button button i.disabled {
  opacity: 0.6;
}

.pagination-custom-button div {
  width: 28px;
  height: 47px;
  font-size: 1em;
  font-weight: 500;
  background: #FFFFFF;
  border: solid rgba(0,0,0,0.3);
  border-width: 1px 0 1px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Skeleton */
.skeleton-button,
.skeleton-wrapper {
  margin-bottom: 20px;
}

.skeleton-title {
  height: 18px;
  margin-bottom: 10px;
}

.skeleton-filter,
.skeleton-organization {
  margin-right: 30px;
}

.skeleton-wrapper {
  display: grid;
  grid-template-columns: auto auto;
}
</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
